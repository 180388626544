@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* overridden css for lib components */

.react_pagination_ul {
  display: flex;
  flex-wrap: wrap;
}
